import { gql } from '@apollo/client';

const ManualInvoicesBySupplier = gql`
  query getManualInvoicesBySupplier($supplierId: UUID!) {
    getManualInvoicesBySupplier(supplierId: $supplierId) {
      id
      customerName
      customerEmail
      invoiceNumber
      createdAt
      lastEmailSent
      invoiceDueDate
      status
      amount
      invoiceLink
      description
      items {
        itemName
        pricePerItem
        quantity
      }
      customerExists
      transferredToSupplierDate
      markedAsManuallyPaidAt
    }
  }
`;

export default ManualInvoicesBySupplier;
