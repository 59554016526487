import { gql } from '@apollo/client';

const activityCreate = gql`
  mutation supplierUpdate($input: MarketplaceSupplierUpdateInput!) {
    supplierUpdate(input: $input) {
      supplier {
        id
        slug
        keySupplierContactName
        name
        phoneNumber
        aboutUs
        ageMonthsStart
        ageMonthsEnd
        cloudinaryImageId
        additionalImageIds
        email
        website
        facebook
        instagram
        twitter
        categories {
          id
          name
        }
        location {
          city
          addressLine1
          addressLine2
          postCode
        }
        activitiesLocation
        links {
          name
          url
          isDeleted
        }
        additionalInformationCaptureEnabled
        logoImageId
        emailHeaderImageId
        replyToAddress
      }
    }
  }
`;

export default activityCreate;
