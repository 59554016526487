import { Text, useMantineTheme } from '@mantine/core';
import { InviteAttendeeTabsEnum, PebbleButtonsEnum } from 'enums';
import classes from './ManuallyAddedScreen.module.scss';
import { handleEmailsValidation } from '../EmailAddition/EmailAddition.utils';
import EmailAddition from '../EmailAddition/EmailAddition';
import { Actions, trackAction } from 'utils/amplitude';
import { CaretLeft } from '@phosphor-icons/react';
import { PebbleButtonSet } from 'components/common';
import { useMediaQuery } from '@mantine/hooks';

interface IManuallyAddedScreenProps {
  setActiveTab(val: InviteAttendeeTabsEnum): void;
  emailInputs: string[];
  setEmailInputs(val: string[]): void;
  emailErrors: { [index: number]: string } | null;
  setEmailErrors(val: { [index: number]: string } | null): void;
}

const ManuallyAddedScreen: React.FC<IManuallyAddedScreenProps> = ({
  setActiveTab,
  emailInputs,
  setEmailInputs,
  emailErrors,
  setEmailErrors,
}: IManuallyAddedScreenProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  return (
    <div className={classes.wrapper}>
      <PebbleButtonSet
        className={classes.backButton}
        btnVariant={PebbleButtonsEnum.TERTIARY}
        size="md"
        leftSection={<CaretLeft weight="bold" />}
        onClick={() => {
          setActiveTab(InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL);
          trackAction(Actions.ENROL_BACK);
        }}
        pl={0}
      >
        Back
      </PebbleButtonSet>
      <Text className={classes.title}>Invite Customers</Text>
      <Text className={classes.copy}>
        Invite customers to book this activity. Add customer emails below to reach out and let them
        know about this new activity.
      </Text>

      <EmailAddition
        emailInputs={emailInputs}
        setEmailInputs={setEmailInputs}
        emailErrors={emailErrors}
        setEmailErrors={setEmailErrors}
        manualScreen={true}
      />

      <PebbleButtonSet
        btnVariant={PebbleButtonsEnum.PRIMARY}
        size="md"
        fullWidth
        mb={isMobile ? 0 : 40}
        onClick={() => {
          handleEmailsValidation(
            emailInputs,
            setEmailErrors,
            setActiveTab,
            InviteAttendeeTabsEnum.INVITE_MESSAGE,
          );
        }}
      >
        Next
      </PebbleButtonSet>
    </div>
  );
};

export default ManuallyAddedScreen;
