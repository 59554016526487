import { FC, useContext, useEffect, useState } from 'react';
import { Title, Text, Modal, Group } from '@mantine/core';
import classes from './LeavePageModal.module.scss';
import { useRouter, Router } from 'next/router';
import { Actions, trackAction } from 'utils/amplitude';
import { ClonedActivityContext } from 'utils/clonedActivityContext/clonedActivityContext';
import { ClonedActivityContextType } from 'types/index';
import { PebbleButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';

interface ILeavePageModalProps {
  messageText: string;
  formIsDirty: boolean;
  isManageActivity?: boolean;
}

export const LeavePageModal: FC<ILeavePageModalProps> = ({
  messageText,
  formIsDirty,
  isManageActivity = false,
}) => {
  const nextRouter = useRouter();
  const [route, setRoute] = useState('');
  const [shouldWarnBeforeExit, setShouldWarnBeforeExit] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { resetClonedActivity } = useContext(ClonedActivityContext) as ClonedActivityContextType;

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (nextRouter.asPath === url) {
        return;
      }
      if (formIsDirty && shouldWarnBeforeExit) {
        setShouldWarnBeforeExit(false);
        setOpenModal(true);
        setRoute(url);
        Router.events.emit('routeChangeError');
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw `routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.`;
      }
    };

    Router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [formIsDirty, shouldWarnBeforeExit, nextRouter?.asPath]);

  const onLeaveClick = () => {
    setOpenModal(false);
    return nextRouter.push(route);
  };

  const title = 'Leave this page?';
  return (
    <Modal
      opened={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      withCloseButton={false}
      centered
      size={640}
      title={title}
      classNames={{
        content: classes.modal,
        header: classes.header,
      }}
    >
      <Title order={4} className={classes.modalTitle}>
        {title}
      </Title>
      <Text size={'lg'} pb={'lg'}>
        Your {messageText} will be lost.
      </Text>
      <Group justify="center" align="center">
        <PebbleButtonSet
          btnVariant={PebbleButtonsEnum.PRIMARY}
          size="md"
          onClick={() => {
            setShouldWarnBeforeExit(true);
            setOpenModal(false);
            trackAction(Actions.STAY_ON_PAGE);
          }}
        >
          Stay on this page
        </PebbleButtonSet>
        <PebbleButtonSet
          btnVariant={PebbleButtonsEnum.SECONDARY}
          size="md"
          onClick={() => {
            onLeaveClick();
            trackAction(Actions.LEAVE_PAGE);
            if (isManageActivity) {
              resetClonedActivity();
            }
          }}
        >
          Yes, leave
        </PebbleButtonSet>
      </Group>
    </Modal>
  );
};
