import { TextInput, TextInputProps } from '@mantine/core';
import { ScheduledActivityViewingModeEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './PebbleTextInput.module.scss';
import classNames from 'classnames';
import { forwardRef } from 'react';

interface IPebbleTextInputProps extends TextInputProps {
  action?: Actions;
  trackingData?: Record<string, string | number | undefined>;
  whiteBackground?: boolean;
  labelSizeMd?: boolean;
  viewingMode?: ScheduledActivityViewingModeEnum;
  fieldError?: boolean;
  defaultInput?: boolean;
}

export const PebbleTextInput: React.FC<IPebbleTextInputProps> = forwardRef<
  HTMLInputElement,
  IPebbleTextInputProps
>(
  (
    {
      onBlur,
      action,
      viewingMode,
      whiteBackground,
      labelSizeMd,
      fieldError,
      defaultInput,
      trackingData,
      ...props
    },
    ref,
  ) => {
    return (
      <TextInput
        ref={ref}
        {...props}
        classNames={{
          input: classNames(!defaultInput && classes.input, {
            [classes.disabledInput]: props.disabled,
            [classes.inputError]: fieldError,
            [classes.whiteBgInput]: whiteBackground,
          }),
          root: classes.inputWrapper,
          label: classNames(classes.label, {
            [classes.disabledLabel]: props.disabled,
            [classes.labelSizeMd]: labelSizeMd,
          }),
          section: classes.inputRightSection,
          ...props.classNames,
        }}
        onBlur={(e) => {
          onBlur?.(e);
          if (action) {
            trackAction(action, { mode: viewingMode, ...trackingData });
          }
        }}
      />
    );
  },
);
