import { FC } from 'react';
import classes from './SuccessScreen.module.scss';
import { ActionIcon, Box, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import { Actions, trackAction } from 'utils/amplitude';
import { Warning } from '@phosphor-icons/react';
import { GreenSuccessCheck } from '@icons';
import { PebbleButtonSet } from 'components/common';
import { useMediaQuery } from '@mantine/hooks';
import { PebbleButtonsEnum } from 'enums';

interface ISuccessScreenProps {
  confirmClose: () => void;
}

const SuccessScreen: FC<ISuccessScreenProps> = ({ confirmClose }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  return (
    <Flex align={'center'} direction={'column'} className={classes.wrapper}>
      <ActionIcon variant="transparent" size={64} className={classes.checkCircle}>
        <GreenSuccessCheck size={48} />
      </ActionIcon>
      <Title className={classes.title} mt={20}>
        Invitations sent!
      </Title>
      <Box className={classes.warning} mt={20}>
        <ActionIcon variant="transparent" mr={8}>
          <Warning size={24} weight="fill" color="#EFC830" />
        </ActionIcon>
        <Text component="p">
          Session(s) have <span>not</span> been reserved. If required, update your session capacity
          now and encourage your customers to book ASAP to avoid disappointment.
        </Text>
      </Box>
      <PebbleButtonSet
        btnVariant={PebbleButtonsEnum.PRIMARY}
        size="md"
        mt={28}
        mb={isMobile ? 0 : 40}
        onClick={() => {
          confirmClose();
          trackAction(Actions.ENROL_DONE);
        }}
      >
        Done
      </PebbleButtonSet>
    </Flex>
  );
};

export default SuccessScreen;
