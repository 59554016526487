import { Box, Flex, Group, Skeleton, Stack, Text, useMantineTheme } from '@mantine/core';
import { InviteAttendeeTabsEnum, PebbleButtonsEnum } from 'enums';
import classes from './PreviousActivitySelectionScreen.module.scss';
import ActivityCard from './ActivityCard/ActivityCard';
import { PebbleButtonSet, PebbleCheckbox } from 'components/common';
import ActivitySearchTerms from './ActivitySearchTerms/ActivitySearchTerms';
import { useMediaQuery } from '@mantine/hooks';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Actions, trackAction } from 'utils/amplitude';
import { CaretLeft, Info } from '@phosphor-icons/react';
import { SearchResultActivity } from 'types';

interface IPreviousActivitySelectionScreenProps {
  setActiveTab(val: InviteAttendeeTabsEnum): void;
  token: string;
  supplierId: string;
  selectedActivitiesId: string[];
  setSelectedActivitiesId: Dispatch<SetStateAction<string[]>>;
}

const SkeletonComponent = () => {
  return (
    <Group className={classes.skeletonWrapper}>
      <Skeleton height={8} radius="xl" width="40%" />
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} width="70%" radius="xl" />
    </Group>
  );
};

const PreviousActivitySelectionScreen: React.FC<IPreviousActivitySelectionScreenProps> = ({
  setActiveTab,
  token,
  supplierId,
  selectedActivitiesId,
  setSelectedActivitiesId,
}: IPreviousActivitySelectionScreenProps) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [activitiesSearchResults, setActivitiesSearchResults] = useState<
    SearchResultActivity[] | null
  >([]);
  const [loadingActivities, setLoadingActivities] = useState<boolean>(false);

  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setShowError(false);

    const allSearchResultActivityIds = activitiesSearchResults?.map((activity) => activity.id);

    setSelectedActivitiesId((prev: string[]) => {
      //select all previously non-selected activities
      if (!selectAll && allSearchResultActivityIds) {
        return Array.from(new Set([...prev, ...allSearchResultActivityIds]));
        //deselect all
      } else {
        return prev.filter((prevId) => !allSearchResultActivityIds?.includes(prevId));
      }
    });
    trackAction(Actions.ENROL_ACTIVITY_SELECT_ALL);
  };

  const handleCTAClick = () => {
    if (selectedActivitiesId.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  useEffect(() => {
    if (selectedActivitiesId.length > 0) {
      setShowError(false);
    }
  }, [selectedActivitiesId]);

  //Default Select all checked state if all activities from the search are already selected
  useEffect(() => {
    const allSearchResultsIncludedInSelected = (): boolean => {
      const allSearchResultActivityIds = activitiesSearchResults?.map((activity) => activity.id);
      return Boolean(
        allSearchResultActivityIds &&
          selectedActivitiesId.length > 0 &&
          allSearchResultActivityIds.every((id) => selectedActivitiesId.includes(id)),
      );
    };

    setSelectAll(allSearchResultsIncludedInSelected());
  }, [activitiesSearchResults, selectedActivitiesId, setSelectAll]);

  return (
    <div className={classes.wrapper}>
      <PebbleButtonSet
        className={classes.backButton}
        btnVariant={PebbleButtonsEnum.TERTIARY}
        size="md"
        leftSection={<CaretLeft weight="bold" />}
        onClick={() => {
          setActiveTab(InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL);
          trackAction(Actions.ENROL_BACK);
        }}
        pl={0}
      >
        Back
      </PebbleButtonSet>
      <Text className={classes.title}>Invite Customers</Text>
      <Group className={classes.copy} gap={12}>
        <Text component="p">Invite customers to book this activity.</Text>
        <Text component="p">
          Find and select activities to reach out to customers and let them know about this new
          activity.
        </Text>
      </Group>

      <ActivitySearchTerms
        token={token}
        supplierId={supplierId}
        setActivitiesSearchResults={setActivitiesSearchResults}
        setLoadingActivities={setLoadingActivities}
      />
      <div className={classes.activityCardWrapper} data-testid="results">
        {showError && (
          <Text component="p" c="red" size="xs" mb="md" fw="600">
            Select an activity
          </Text>
        )}
        {selectedActivitiesId.length > 0 && !loadingActivities && !activitiesSearchResults && (
          <Text className={classes.endOfList} mt={24}>
            {selectedActivitiesId.length}{' '}
            {selectedActivitiesId.length === 1 ? 'activity' : 'activities'} selected
          </Text>
        )}
        {activitiesSearchResults && activitiesSearchResults.length > 0 && (
          <>
            <Flex justify="space-between" className={classes.selectAllCheckbox} mb={24}>
              <PebbleCheckbox
                label={'Select all'}
                labelPosition="right"
                onChange={handleSelectAll}
                checked={selectAll}
              />
              <Text className={classes.activitiesSelected}>
                {selectedActivitiesId.length} selected
              </Text>
            </Flex>
            {activitiesSearchResults?.map((activity) => (
              <>
                <ActivityCard
                  key={activity.id}
                  {...activity}
                  setSelectedActivitiesId={setSelectedActivitiesId}
                  selected={selectedActivitiesId.includes(activity.id)}
                />
              </>
            ))}
            <Text className={classes.endOfList} mt={24}>
              You have reached the end of activity list
            </Text>
          </>
        )}
        {!loadingActivities && activitiesSearchResults && activitiesSearchResults.length === 0 && (
          <Box className={classes.noActivities} mt={32}>
            No matching activities
          </Box>
        )}
        {loadingActivities && (
          <Stack gap={12} mt={32}>
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
          </Stack>
        )}
      </div>

      <Flex align={isMobile ? 'start' : 'center'} className={classes.warningWrapper}>
        <Info size={28} color={theme.colors.blue[8]} />
        <Text className={classes.warningCopy}>
          On the next screen you'll be able to add and remove individuals.
        </Text>
      </Flex>

      <PebbleButtonSet
        btnVariant={PebbleButtonsEnum.PRIMARY}
        size="md"
        fullWidth
        mb={isMobile ? 0 : 40}
        onClick={() => {
          if (selectedActivitiesId.length > 0) {
            setActiveTab(InviteAttendeeTabsEnum.REVIEW_CUSTOMERS_SCREEN);
            trackAction(Actions.ENROL_REVIEW_CUSTOMERS);
          }
          handleCTAClick();
        }}
      >
        Review customers
      </PebbleButtonSet>
    </div>
  );
};

export default PreviousActivitySelectionScreen;
