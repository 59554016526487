import { gql } from '@apollo/client';

const ManageActivityQuery = gql`
  query manageActivityQuery($supplierId: UUID!, $activityId: UUID!) {
    supplierAdmin {
      id
      emailAddress
      fullName
      cognitoId
      cognitoLastAuthTime
      cognitoUserPoolId
      suppliers {
        id
        location {
          addressLine1
          addressLine2
          city
          postCode
        }
        name
        phoneNumber
        email
        website
        keySupplierContactName
        paymentAccount {
          stripeAccountAuthorised
        }
      }
    }
    activitiesBySupplier(supplierId: $supplierId, activityId: $activityId) {
      activities {
        activityTags
        id
        name
        slug
        description
        subscriptionInfo
        ageMonthsEnd
        ageMonthsStart
        ageStartShowInMonths
        ageEndShowInMonths
        locationName
        location {
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        supplierLocationId
        guardianToProvide
        supplierToProvide
        allowBlockBookings
        allowIndividualBookings
        blockTrialType
        blockTrialSessionCount
        blockTrialPrice
        classes {
          id
          name
          term {
            id
            name
            offDates
          }
          hasBookings
          totalBookings
          totalCapacity
          blocks {
            id
            sessions {
              id
            }
          }
          sessions {
            id
            date
            dayOfTheWeek
            capacity
            bookingCount
            startTime
            endTime
            addons {
              id
            }
          }
          tickets {
            id
          }
          addons {
            id
          }
        }
        tickets {
          block {
            id
            name
            description
            price
            capacity
            availabilityStartDate
            availabilityEndDate
            pricingPolicy
            allowBookingsAfterFirstSession
          }
          individual {
            id
            name
            description
            price
            capacity
            availabilityStartDate
            availabilityEndDate
            pricingPolicy
            allowBookingsAfterFirstSession
          }
          subscription {
            id
            name
            description
            price
            capacity
            availabilityStartDate
            availabilityEndDate
            pricingPolicy
            allowBookingsAfterFirstSession
          }
        }
        cloudinaryImageId
        additionalImageIds
        email
        minDate
        maxDate
        times {
          startTime
          endTime
        }
        allowBlockBookings
        allowIndividualBookings
        blockSubtype
        bookingType
        activityType
        hasBookings
        hideEndTimes
        schedules {
          id
          startTime
          endTime
          dayOfTheWeek
          capacity
          activeSubs
        }
        offDates {
          date
        }
        subscriptionTrialType
        subscriptionTrialSessionCount
        subscriptionTrialPrice
        subscriptionStart
        categories {
          id
          name
        }
        isOnline
        onlinePublicInfo
        onlinePrivateInfo
        ofstedUrn
        ponchoPayAccepted
        locationAdditionalInfo
        questions {
          id
          title
          shortTitle
          type
          isPerAttendee
          isDefault
        }
        activityMemberships {
          id
          supplierAdmin {
            id
            fullName
          }
          isSuperadmin
          activityRegistersAccess
        }
        addons {
          id
          name
          description
          price
          isPublished
          isDeleted
          addonType
        }
        customConfirmationEmailImageId
        customConfirmationEmailHeader
        customConfirmationEmailMessage
        customConfirmationEmailFurtherInfo
        sessionRemindersEnabled
        customReminderEmailFurtherInfo
        enableRegisterCheckinCheckoutMode
      }
    }
    termsBySupplier(supplierId: $supplierId) {
      id
      isDeleted
      createdAt
      updatedAt
      name
      startDate
      endDate
      offDates
      supplier {
        id
      }
    }
    categories {
      id
      name
    }
    marketplaceSupplierMemberships(supplierId: $supplierId) {
      isSuperadmin
      id
      adminAccess {
        id
        name
        niceName
      }
      supplierAdmin {
        id
        fullName
      }
    }
  }
`;

export default ManageActivityQuery;
