import { Button, ButtonProps, MantineSize, useMantineTheme } from '@mantine/core';
import classes from './PebbleButtonSet.module.scss';
import { AriaRole, MouseEventHandler, forwardRef } from 'react';
import classNames from 'classnames';
import { PebbleButtonsEnum } from 'enums';

interface PebbleButtonProps extends ButtonProps {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  type?: 'button' | 'submit' | 'reset';
  btnVariant?: PebbleButtonsEnum;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  size?: MantineSize;
  component?: 'a';
  href?: string;
  target?: string;
  role?: AriaRole;
  title?: string;
}

export const PebbleButtonSet = forwardRef<HTMLButtonElement | HTMLAnchorElement, PebbleButtonProps>(
  (
    {
      children,
      onClick,
      type,
      btnVariant,
      leftSection,
      rightSection,
      size,
      component,
      href,
      role,
      ...props
    },
    ref,
  ) => {
    const theme = useMantineTheme();

    const isLink = href !== undefined && component === 'a';

    const getButtonHeight = () => {
      switch (size) {
        case 'lg': {
          return '40px';
        }
        case 'md': {
          return '32px';
        }
        case 'sm': {
          return '28px';
        }
        case 'xs': {
          return '24px';
        }
        default: {
          return 'auto';
        }
      }
    };

    const getInlinePadding = () => {
      switch (size) {
        case 'lg': {
          return '20px';
        }
        case 'md': {
          return '14px';
        }
        case 'sm': {
          return '12px';
        }
        case 'xs': {
          return '12px';
        }
        default: {
          return 'auto';
        }
      }
    };

    return (
      <Button
        {...props}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        component={isLink ? 'a' : 'button'}
        role={role || (isLink ? 'link' : 'button')}
        href={isLink ? href : undefined}
        target={props?.target}
        variant="transparent"
        onClick={onClick}
        type={type || 'button'}
        leftSection={leftSection}
        rightSection={rightSection}
        h={props.h ? props.h : getButtonHeight()}
        w={props.w ? props.w : props.fullWidth ? '100%' : 'fit-content'}
        px={leftSection || rightSection ? 'auto' : getInlinePadding()}
        classNames={{
          root: classNames(classes.root, {
            [classes.primaryBtn]: btnVariant === PebbleButtonsEnum.PRIMARY,
            [classes.secondaryBtn]: btnVariant === PebbleButtonsEnum.SECONDARY,
            [classes.tertiaryBtn]: btnVariant === PebbleButtonsEnum.TERTIARY,
            [classes.disabled]: props.disabled && btnVariant !== PebbleButtonsEnum.TERTIARY,
            [classes.disabledTertiary]:
              btnVariant === PebbleButtonsEnum.TERTIARY && (props.disabled || props.loading),
          }),
          label: classNames({
            [classes.semiboldBtnLabel]: btnVariant === PebbleButtonsEnum.TERTIARY || props.disabled,
            [classes.lgBtn]: size === 'lg',
            [classes.mdBtn]: size === 'md',
            [classes.smBtn]: size === 'sm',
            [classes.xsBtn]: size === 'xs',
          }),
        }}
        fw={props?.fw ? props.fw : btnVariant === PebbleButtonsEnum.TERTIARY ? 600 : 'initial'}
        loading={
          btnVariant !== PebbleButtonsEnum.TERTIARY && !props.disabled ? props.loading : false
        }
        loaderProps={{
          type: 'dots',
          color: btnVariant === PebbleButtonsEnum.PRIMARY ? 'white' : theme.colors.pink[6],
        }}
      >
        {children}
      </Button>
    );
  },
);
