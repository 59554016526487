import { gql } from '@apollo/client';

const ResumeSubscriptionBooking = gql`
  mutation resumeSubscriptionBooking($subscriptionId: String!) {
    resumeSubscriptionBooking(subscriptionId: $subscriptionId) {
      subscriptionRequest {
        id
        status
      }
    }
  }
`;

export default ResumeSubscriptionBooking;
