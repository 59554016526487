import { gql } from '@apollo/client';

const changeManualInvoiceStatus = gql`
  mutation changeManualInvoiceStatus($input: ChangeManualInvoiceStatusInput!) {
    changeManualInvoiceStatus(input: $input) {
      manualInvoice {
        id
        customerName
        invoiceNumber
        status
      }
    }
  }
`;

export default changeManualInvoiceStatus;
