import { FC, useState } from 'react';
import Image from 'next/legacy/image';
import { Center, Group, Stack, Skeleton, Table, Text, Title } from '@mantine/core';
import { Term } from 'types';
import { isNewlyCreated } from '../../../../../utils/isNewlyCreated';
import dayjs from 'dayjs';
import { TermViewingModal } from '../TermViewingModal';
import { DeleteModal } from '../DeleteModal';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './TableView.module.scss';
import classNames from 'classnames';
import { X } from '@phosphor-icons/react';
import { PebbleButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';

interface ITableViewProps {
  loading: boolean;
  onDelete(termId: string): void;
  termsBySupplier: Term[];
}

export const TableView: FC<ITableViewProps> = (props) => {
  const { loading, onDelete, termsBySupplier } = props;
  const [chosenTerm, setChosenTerm] = useState<Term | null>(null);
  // Delete State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [termToDelete, setTermToDelete] = useState<string>('');
  const [deletedTermName, setDeletedTermName] = useState<string>('');

  // View State
  const [showViewingModal, setShowViewingModal] = useState(false);

  const handleOnClose = () => {
    setShowViewingModal(false);
  };

  const handleViewClick = (termID: string) => {
    const findChosenTerm = termsBySupplier.find((term) => {
      return term.id === termID;
    });
    if (findChosenTerm) {
      setChosenTerm(findChosenTerm);
    }
  };

  return (
    <>
      <Title order={4} mb="lg">
        Your terms
      </Title>
      <Table className={classes.table} data-cy="test-id-terms-table">
        <thead>
          <tr>
            <th>Term name</th>
            <th colSpan={2}>Term dates</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td className="tdNoResult" colSpan={2}>
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                <Skeleton height={24} mb="sm" width="100%" radius="xs" />
              </td>
            </tr>
          ) : termsBySupplier?.length ? (
            termsBySupplier?.map((term: Term) => (
              <tr
                key={term.id}
                className={classNames({ [classes.new]: isNewlyCreated(term.createdAt) })}
              >
                <td>{term.name}</td>
                <td>
                  {dayjs(term.startDate).format('DD | MM | YY')}
                  <span className={classes.dateDivider}> to </span>
                  {dayjs(term.endDate).format('DD | MM | YY')}
                </td>
                <td>
                  <Group wrap="nowrap" gap="xs">
                    <PebbleButtonSet
                      btnVariant={PebbleButtonsEnum.PRIMARY}
                      size="sm"
                      onClick={() => {
                        setShowViewingModal(true);
                        handleViewClick(term.id);
                        trackAction(Actions.VIEW_TERM);
                      }}
                    >
                      View
                    </PebbleButtonSet>
                    <PebbleButtonSet
                      btnVariant={PebbleButtonsEnum.SECONDARY}
                      size="sm"
                      leftSection={<X weight="bold" size={16} />}
                      onClick={() => {
                        setTermToDelete(term?.id);
                        setShowDeleteModal(true);
                        setDeletedTermName(term?.name);
                        trackAction(Actions.DELETE_TERM);
                      }}
                    >
                      Delete
                    </PebbleButtonSet>
                  </Group>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="tdNoResult" colSpan={5}>
                <Center className={classes.noResult}>
                  <Stack>
                    <Image src="/cubes.png" alt="Stacked cubes" width={140} height={78} />
                    <Text ta="center">
                      You’ve not created any terms yet.
                      <br />
                      Once you do, they will appear here.
                    </Text>
                  </Stack>
                </Center>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <DeleteModal
        open={showDeleteModal && !!termToDelete}
        onDelete={() => {
          setTermToDelete('');
          setShowDeleteModal(false);
          onDelete(termToDelete);
        }}
        onCancel={() => {
          setTermToDelete('');
          setShowDeleteModal(false);
        }}
        termName={deletedTermName}
      />
      {chosenTerm && (
        <TermViewingModal
          showViewingModal={showViewingModal}
          onClose={handleOnClose}
          chosenTerm={chosenTerm}
        />
      )}
    </>
  );
};
