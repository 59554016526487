import { PebbleButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';
import { FC } from 'react';
import { Text, Group, useMantineTheme, Center } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';

interface IRequestPayment {
  supplierId: string;
}

export const RequestPayment: FC<IRequestPayment> = ({ supplierId }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const manageInvoicesButton = () => {
    return (
      <PebbleButtonSet
        btnVariant={PebbleButtonsEnum.SECONDARY}
        size="md"
        component="a"
        href={`/manage-invoices?supplierId=${supplierId}`}
        mt={isMobile ? 'lg' : 'auto'}
        mb={isMobile ? 'xs' : 'xl'}
        onClick={() => trackAction(Actions.MANAGE_INVOICES)}
        w={isMobile ? '100%' : 'auto'}
      >
        Manage Invoices
      </PebbleButtonSet>
    );
  };
  return !isMobile ? (
    <Group justify="space-between">
      <Text size="md" fw={400} c={theme.colors.gray[6]} mt={isMobile ? 'sm' : ''}>
        Manage, track and send invoices
      </Text>
      {manageInvoicesButton()}
    </Group>
  ) : (
    <>
      <Text size="md" fw={400} c={theme.colors.gray[6]} mt={isMobile ? 'sm' : ''}>
        Manage, track and send invoices
      </Text>
      <Center>{manageInvoicesButton()}</Center>
    </>
  );
};
