import { FC, useState } from 'react';
import { Container, Grid, Overlay, Text } from '@mantine/core';
import { PageHeader } from 'components';
import Cards from './components/Card/Card';
import ActivityFinancialReport from './components/ActivityFinancialReport/ActivityFinancialReport';
import Setup from './components/Setup/Setup';
import UpcomingClasses from './components/UpcomingClasses/UpcomingClasses';
import ClassCapacity from './components/ClassCapacity/ClassCapacity';
import classes from './Home.module.scss';
import Link from 'next/link';
import { RequestPayment } from './components/RequestPayment/RequestPayment';

interface IHome {
  token: string;
  supplierId: string;
  supplierName: string;
  stripeLinked?: boolean;
}

export enum ComponentTypes {
  SETUP = 'setup',
  UPCOMING = 'upcoming',
  CAPACITY = 'capacity',
  FINANCE = 'finance',
  PERFORMANCE = 'performance',
  REQUEST_PAYMENT = 'request_payment',
}

export const Home: FC<IHome> = (props) => {
  const { token, supplierId, supplierName, stripeLinked } = props;

  const [setupComplete, setSetupComplete] = useState<boolean | undefined>(true);
  const [dateFilterActive, setDateFilterActive] = useState(false);
  const [noUpcomingClasses, setNoUpcomingClasses] = useState(false);
  const [classCapacityUpToDate, setClassCapacityUpToDate] = useState(false);

  return (
    <>
      <PageHeader title={'Dashboard'} />
      <Container className={classes.container} fluid>
        <Grid pb="xl" columns={12} classNames={{ col: classes.gridCol }}>
          <Grid.Col span={12} hidden={setupComplete}>
            <Cards section={ComponentTypes.SETUP}>
              <Setup token={token} supplierId={supplierId} setSetupComplete={setSetupComplete} />
            </Cards>
          </Grid.Col>
          <Grid.Col
            span={{
              base: 12,
              md: noUpcomingClasses ? 12 : 6,
              lg: noUpcomingClasses ? 12 : 4,
            }}
          >
            <Cards section={ComponentTypes.UPCOMING}>
              <UpcomingClasses
                token={token}
                supplierId={supplierId}
                setNoUpcomingClasses={setNoUpcomingClasses}
              />
            </Cards>
          </Grid.Col>
          {!noUpcomingClasses && (
            <Grid.Col
              span={{
                base: 12,
                md: 6,
                lg: 8,
              }}
            >
              <Cards section={ComponentTypes.CAPACITY} fullHeight={classCapacityUpToDate}>
                <ClassCapacity
                  token={token}
                  supplierId={supplierId}
                  setClassCapacityUpToDate={setClassCapacityUpToDate}
                />
              </Cards>
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <Cards section={ComponentTypes.FINANCE}>
              {stripeLinked ? (
                <ActivityFinancialReport
                  token={token}
                  supplierId={supplierId}
                  supplierName={supplierName}
                  dateFilterActive={dateFilterActive}
                  setDateFilterActive={setDateFilterActive}
                />
              ) : (
                <Text>
                  Get paid faster!{' '}
                  <Link
                    className={classes.setUpLink}
                    href={`/settings?supplierId=${supplierId}&activeSettingsTab=payment-details`}
                  >
                    Set up
                  </Link>{' '}
                  your Stripe account today.
                </Text>
              )}
            </Cards>
            {dateFilterActive && <Overlay zIndex={2} opacity={0.4} color="#000" fixed />}
          </Grid.Col>
          <Grid.Col span={12}>
            <Cards section={ComponentTypes.REQUEST_PAYMENT}>
              <RequestPayment supplierId={supplierId} />
            </Cards>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};
