import { Drawer, Flex, Modal, Tabs, Text, useMantineTheme } from '@mantine/core';
import { InviteAttendeeTabsEnum, PebbleButtonsEnum } from 'enums';
import { useEffect, useRef, useState } from 'react';
import classes from './InviteAttendee.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import PreviousActivitiesOrManualScreen from './PreviousActivitiesOrManualScreen/PreviousActivitiesOrManualScreen';
import ManuallyAddedScreen from './ManuallyAddedScreen/ManuallyAddedScreen';
import PreviousActivitySelectionScreen from './PreviousActivitySelectionScreen/PreviousActivitySelectionScreen';
import ReviewCustomersScreen from './ReviewCustomersScreen/ReviewCustomersScreen';
import InviteMessage from './InviteMessage/InviteMessage';
import SuccessScreen from './SuccessScreen/SuccessScreen';
import { useMutation } from '@apollo/client';
import { supplierBookingRequestCreateMutation } from '../../graphql/mutation';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { Actions, trackAction } from 'utils/amplitude';
import { X } from '@phosphor-icons/react';
import { PebbleButtonSet } from 'components/common';

interface IInviteAttendeeProps {
  openInviteAttendeeContent: boolean;
  setOpenInviteAttendeeContent(val: boolean): void;
  token: string;
  activityId: string;
  supplierId: string;
}

const InviteAttendee: React.FC<IInviteAttendeeProps> = ({
  openInviteAttendeeContent,
  setOpenInviteAttendeeContent,
  token,
  activityId,
  supplierId,
}: IInviteAttendeeProps) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const [activeTab, setActiveTab] = useState<InviteAttendeeTabsEnum>(
    InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL,
  );

  const [selectedActivitiesId, setSelectedActivitiesId] = useState<string[]>([]);

  const [confirmOrGoBack, setConfirmOrGoBack] = useState(false);

  const [emailInputs, setEmailInputs] = useState<string[]>(['']);
  const [emailErrors, setEmailErrors] = useState<{ [index: number]: string } | null>(null);
  const [customerEmails, setCustomerEmails] = useState<string[]>([]);
  const [customMessage, setCustomMessage] = useState('');
  const [followUpDates, setFollowUpDates] = useState<string[]>([]);

  const getUniqueEmails = () => {
    if (customerEmails?.length > 0 && emailInputs[0] !== '') {
      return Array.from(new Set([...customerEmails, ...emailInputs]));
    }
    return customerEmails?.length > 0 ? customerEmails : emailInputs;
  };

  const confirmClose = () => {
    setOpenInviteAttendeeContent(false);
    setConfirmOrGoBack(false);
    setActiveTab(InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL);
    setEmailInputs(['']);
    setEmailErrors(null);
    setCustomerEmails([]);
    setSelectedActivitiesId([]);
    setCustomMessage('');
    setFollowUpDates([]);
  };

  const cancelClose = () => {
    setConfirmOrGoBack(false);
  };

  const onClose = () => {
    trackAction(Actions.ENROL_CLOSE);
    if (
      activeTab === InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL ||
      activeTab === InviteAttendeeTabsEnum.SUCCESS_SCREEN ||
      (activeTab === InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN && emailInputs[0] !== '')
    ) {
      confirmClose();
    } else {
      setConfirmOrGoBack(true);
    }
  };

  const previousTabName = useRef<string>(activeTab);

  useEffect(() => {
    // prevent previousTabName being overridden when entering an invite message
    if (activeTab !== InviteAttendeeTabsEnum.INVITE_MESSAGE) {
      previousTabName.current = activeTab;
    }
  }, [activeTab]);

  const [publishChanges] = useMutation(supplierBookingRequestCreateMutation, {
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const handlePublish = () => {
    if (activityId && token) {
      publishChanges({
        variables: {
          activityId,
          emails: getUniqueEmails(),
          optIn: true,
          ...(customMessage !== '' && {
            customMessage,
          }),
          ...(followUpDates.length > 0 && {
            sendAt: followUpDates,
          }),
        },
        context: {
          headers: {
            Authorization: `${token}`,
          },
        },
      });
    }
  };

  const renderTabContent = () => {
    return (
      <Tabs
        classNames={{
          list: classes.tabsList,
        }}
        value={activeTab}
        onChange={(val) => setActiveTab(val as InviteAttendeeTabsEnum)}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab
            disabled={activeTab !== InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL}
            value={InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL}
          />
          <Tabs.Tab
            disabled={activeTab !== InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN}
            value={InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN}
          />
          <Tabs.Tab
            disabled={activeTab !== InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_SELECTION}
            value={InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_SELECTION}
          />
          <Tabs.Tab
            disabled={activeTab !== InviteAttendeeTabsEnum.REVIEW_CUSTOMERS_SCREEN}
            value={InviteAttendeeTabsEnum.REVIEW_CUSTOMERS_SCREEN}
          />
          <Tabs.Tab
            disabled={activeTab !== InviteAttendeeTabsEnum.INVITE_MESSAGE}
            value={InviteAttendeeTabsEnum.INVITE_MESSAGE}
          />
          <Tabs.Tab
            disabled={activeTab !== InviteAttendeeTabsEnum.SUCCESS_SCREEN}
            value={InviteAttendeeTabsEnum.SUCCESS_SCREEN}
          />
        </Tabs.List>

        <Tabs.Panel value={InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL}>
          <PreviousActivitiesOrManualScreen setActiveTab={setActiveTab} />
        </Tabs.Panel>
        <Tabs.Panel value={InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN}>
          <ManuallyAddedScreen
            setActiveTab={setActiveTab}
            emailInputs={emailInputs}
            setEmailInputs={setEmailInputs}
            emailErrors={emailErrors}
            setEmailErrors={setEmailErrors}
          />
        </Tabs.Panel>
        <Tabs.Panel value={InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_SELECTION}>
          <PreviousActivitySelectionScreen
            setActiveTab={setActiveTab}
            supplierId={supplierId}
            token={token}
            selectedActivitiesId={selectedActivitiesId}
            setSelectedActivitiesId={setSelectedActivitiesId}
          />
        </Tabs.Panel>
        <Tabs.Panel value={InviteAttendeeTabsEnum.REVIEW_CUSTOMERS_SCREEN}>
          <ReviewCustomersScreen
            setActiveTab={setActiveTab}
            emailInputs={emailInputs}
            setEmailInputs={setEmailInputs}
            emailErrors={emailErrors}
            setEmailErrors={setEmailErrors}
            supplierId={supplierId}
            token={token}
            selectedActivitiesId={selectedActivitiesId}
            setCustomerEmails={setCustomerEmails}
            customerEmails={customerEmails}
          />
        </Tabs.Panel>
        <Tabs.Panel value={InviteAttendeeTabsEnum.INVITE_MESSAGE}>
          <InviteMessage
            setActiveTab={setActiveTab}
            prevTab={previousTabName.current}
            setCustomMessage={setCustomMessage}
            setFollowUpDates={setFollowUpDates}
            followUpDates={followUpDates}
            handlePublish={handlePublish}
          />
        </Tabs.Panel>
        <Tabs.Panel value={InviteAttendeeTabsEnum.SUCCESS_SCREEN}>
          <SuccessScreen confirmClose={confirmClose} />
        </Tabs.Panel>
      </Tabs>
    );
  };

  const leaveInviteWarning = () => {
    return (
      <Flex align="center" direction="column" py={'40px'}>
        <Text className={classes.title}>Are you sure you want to leave?</Text>
        <Text fw={600} pb="xl">
          Your data will be lost.
        </Text>
        <Flex gap="lg" direction={isMobile ? 'column' : 'row'} mb={isMobile ? 'auto' : 'xl'}>
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.PRIMARY}
            size="md"
            w={isMobile ? 300 : 225}
            onClick={cancelClose}
          >
            No, continue
          </PebbleButtonSet>
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.SECONDARY}
            size="md"
            w={isMobile ? 300 : 225}
            onClick={confirmClose}
          >
            Yes, leave
          </PebbleButtonSet>
        </Flex>
      </Flex>
    );
  };

  const getModalSize = () => {
    switch (activeTab) {
      case InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_OR_MANUAL:
        return 450;
      case InviteAttendeeTabsEnum.SUCCESS_SCREEN:
        return 640;
      default:
        return 565;
    }
  };

  return (
    <>
      {isMobile ? (
        <Drawer
          title={
            <X
              weight="bold"
              size={20}
              className={classNames(classes.closeButton, {
                [classes.hideButton]: confirmOrGoBack,
              })}
              onClick={onClose}
            />
          }
          withCloseButton={false}
          opened={openInviteAttendeeContent}
          onClose={onClose}
          classNames={{
            content: classes.drawerContent,
            body: classes.drawerBody,
            header: classNames({
              [classes.drawerHeaderHidden]: confirmOrGoBack,
            }),
          }}
          position="bottom"
          radius={0}
        >
          {confirmOrGoBack ? leaveInviteWarning() : renderTabContent()}
        </Drawer>
      ) : (
        <Modal
          title={
            <X
              weight="bold"
              data-cy="close modal icon"
              size={24}
              className={classNames(classes.closeButton, {
                [classes.hideButton]: confirmOrGoBack,
              })}
              onClick={onClose}
            />
          }
          withCloseButton={false}
          opened={openInviteAttendeeContent}
          onClose={onClose}
          centered
          size={getModalSize()}
          classNames={{
            content: classes.modalContent,
            header: classes.modalHeader,
            body: classes.modalBody,
            close: classes.modalCloseButton,
          }}
        >
          {confirmOrGoBack ? (
            leaveInviteWarning()
          ) : (
            <div className={classes.modalInnerWrapper}>{renderTabContent()}</div>
          )}
        </Modal>
      )}
    </>
  );
};

export default InviteAttendee;
