import { Box, Flex, Spoiler, Stack, Text, Title, Divider, useMantineTheme } from '@mantine/core';
import classes from './ClassCapacity.module.scss';
import { useLazyQuery } from '@apollo/client';
import SupplierClassCapacity from 'graphql/queries/supplier-class-capacity';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useRouter } from 'next/router';
import { Actions, trackAction } from 'utils/amplitude';
import { useMediaQuery } from '@mantine/hooks';
import { ShootingStar, UsersThree } from '@phosphor-icons/react';
import { Capacity } from '@icons';
import { PebbleButtonSet, PebbleIconButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';

interface ClassCapacityProps {
  token: string;
  supplierId: string;
  setClassCapacityUpToDate: Dispatch<SetStateAction<boolean>>;
}

interface ClassCapacityData {
  name: string;
  date: string;
  time: string;
  capacity: number;
  bookings: number;
  percentBooked: number;
  activityId: string;
  activityClass?: string;
}

interface SupplierClassCapacityData {
  supplierClassCapacity: {
    noCapacity?: ClassCapacityData[];
    soldOut?: ClassCapacityData[];
    nearCapacity?: ClassCapacityData[];
  };
}

enum CapacityEvent {
  DASH_NO_CAPACITY = 'dash_no_capacity',
  DASH_SOLD_OUT = 'dash_sold_out',
  DASH_NEAR_CAPACITY = 'dash_near_capacity',
}

const ClassInformation: React.FC<{
  classInfo: ClassCapacityData;
  capacityEvent: CapacityEvent;
  showCapacity?: boolean;
}> = ({
  classInfo: { name, date, time, capacity, bookings, percentBooked, activityId, activityClass },
  capacityEvent,
  showCapacity = false,
}) => {
  const theme = useMantineTheme();
  const router = useRouter();
  const { supplierId } = router.query;
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const getBadgeColor = () => {
    if (percentBooked >= 70 && percentBooked < 80)
      return { backgroundColor: 'rgba(255, 249, 219, 1)', borderColor: 'rgb(250, 176, 5)' };

    if (percentBooked >= 80 && percentBooked < 90)
      return { backgroundColor: 'rgba(255, 244, 230, 1)', borderColor: '#F8840D' };

    if (percentBooked >= 90)
      return { backgroundColor: 'rgba(255, 245, 245, 1)', borderColor: '#D93E1C' };
  };

  const handleEvents = () => {
    switch (capacityEvent) {
      case CapacityEvent.DASH_NO_CAPACITY: {
        return trackAction(Actions.DASH_NO_CAPACITY);
      }
      case CapacityEvent.DASH_SOLD_OUT: {
        return trackAction(Actions.DASH_SOLD_OUT);
      }
      default: {
        return trackAction(Actions.DASH_NEAR_CAPACITY);
      }
    }
  };

  return (
    <>
      <Flex gap={8} align={'center'} mt={theme.spacing.sm}>
        <div className={classes.classInfo}>
          <Text
            className={classes.sessionName}
            title={name}
            style={{
              fontWeight: 600,
            }}
          >
            {name}
          </Text>
          {activityClass && (
            <Text
              mt={5}
              mb="xs"
              size="sm"
              c={theme.colors.gray[6]}
              style={{
                fontWeight: 600,
              }}
            >
              {activityClass}
            </Text>
          )}
          <Text
            size="xs"
            c={theme.colors.gray[6]}
            style={{
              fontWeight: 600,
            }}
          >
            {date}, {time}
          </Text>
        </div>
        {showCapacity && (
          <div className={classes.capacityBadge}>
            <Flex direction="column" align="center">
              <Box style={getBadgeColor()} className={classes.iconWrapper}>
                <UsersThree size={20} color={getBadgeColor()?.borderColor} weight="fill" />
              </Box>
              <Text size="12px" c={theme.colors.gray[6]} fw={700} mt={4}>
                {bookings} / {capacity}
              </Text>
            </Flex>
          </div>
        )}
        {isMobile ? (
          <>
            <PebbleIconButtonSet
              btnVariant={PebbleButtonsEnum.SECONDARY}
              size="md"
              icon={<Capacity size={20} weight="fill" />}
              label="Update"
              onClick={() => {
                handleEvents();
                router.push(`/edit-activity/${activityId}?supplierId=${supplierId}&tab=capacity`);
              }}
            />
          </>
        ) : (
          <div className={classes.updateCapacityBtn}>
            <PebbleButtonSet
              btnVariant={PebbleButtonsEnum.SECONDARY}
              size="sm"
              onClick={() => {
                handleEvents();
                router.push(`/edit-activity/${activityId}?supplierId=${supplierId}&tab=capacity`);
              }}
            >
              Update
            </PebbleButtonSet>
          </div>
        )}
      </Flex>
      <Divider size="xs" />
    </>
  );
};

const defaultData = {
  supplierClassCapacity: {},
};

const ClassCapacity: React.FC<ClassCapacityProps> = ({
  token,
  supplierId,
  setClassCapacityUpToDate,
}) => {
  const [fetchedData, setFetchedData] = useState<SupplierClassCapacityData>(defaultData);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const [getData, { loading }] = useLazyQuery<SupplierClassCapacityData>(SupplierClassCapacity, {
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        data.supplierClassCapacity.nearCapacity?.length === 0 &&
        data.supplierClassCapacity.noCapacity?.length === 0 &&
        data.supplierClassCapacity.soldOut?.length === 0
      ) {
        setClassCapacityUpToDate(true);
      } else {
        setFetchedData(data);
        setClassCapacityUpToDate(false);
      }
    },
    onError: (error) => {
      console.log(error);
      showErrorMessage(error);
    },
  });

  useEffect(() => {
    if (supplierId) {
      setFetchedData(defaultData);
      getData({
        variables: {
          supplierId,
        },
      });
    }
  }, [supplierId, getData, setClassCapacityUpToDate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const {
    supplierClassCapacity: { noCapacity = [], soldOut = [], nearCapacity = [] },
  } = fetchedData as SupplierClassCapacityData;

  return (
    <>
      {noCapacity.length === 0 && soldOut.length === 0 && nearCapacity.length === 0 ? (
        <Flex direction="column" h="100%">
          <Text lh="22px">
            Looking good here! All your upcoming classes have space for more bookings. <br /> Keep
            your schedules up to date to maximise attendance.
          </Text>
          <Flex justify="center" align="center" h="100%" my="lg">
            <ShootingStar
              weight="fill"
              color={theme.colors.blue[2]}
              size={isMobile ? '100px' : '175px'}
            />
          </Flex>
        </Flex>
      ) : (
        <>
          <Text mt="sm" mb="xs" c={theme.colors.gray[6]}>
            Some of your classes are (nearly) sold out — nice one! If you want to add capacity, you
            can do that here.
          </Text>
          <Box hidden={noCapacity.length === 0} className={classes.boxWrapper}>
            <Title order={6} mb="xs">
              No capacity added
            </Title>
            <Spoiler
              maxHeight={isMobile ? 310 : 250}
              showLabel="View more"
              hideLabel="View less"
              classNames={{
                control: classes.spoilerControl,
              }}
            >
              <Stack>
                {noCapacity.map((classInfo) => (
                  <ClassInformation
                    classInfo={classInfo}
                    key={`${classInfo.name}-${classInfo.date}-${classInfo.time}`}
                    capacityEvent={CapacityEvent.DASH_NO_CAPACITY}
                  />
                ))}
              </Stack>
            </Spoiler>
          </Box>
          <Box hidden={soldOut.length === 0} className={classes.boxWrapper}>
            <Title order={6} mb="xs">
              Sold out
            </Title>
            <Spoiler
              maxHeight={isMobile ? 310 : 250}
              showLabel="View more"
              hideLabel="View less"
              classNames={{
                control: classes.spoilerControl,
              }}
            >
              <Stack>
                {soldOut.map((classInfo) => (
                  <ClassInformation
                    classInfo={classInfo}
                    key={`${classInfo.name}-${classInfo.date}-${classInfo.time}`}
                    capacityEvent={CapacityEvent.DASH_SOLD_OUT}
                  />
                ))}
              </Stack>
            </Spoiler>
          </Box>
          <Box hidden={nearCapacity.length === 0} className={classes.boxWrapper}>
            <Title order={6} mb="xs">
              Near capacity
            </Title>
            <Spoiler
              maxHeight={isMobile ? 310 : 250}
              showLabel="View more"
              hideLabel="View less"
              classNames={{
                control: classes.spoilerControl,
              }}
            >
              <Stack>
                {nearCapacity.map((classInfo) => (
                  <ClassInformation
                    classInfo={classInfo}
                    showCapacity={true}
                    key={`${classInfo.name}-${classInfo.date}-${classInfo.time}`}
                    capacityEvent={CapacityEvent.DASH_NEAR_CAPACITY}
                  />
                ))}
              </Stack>
            </Spoiler>
          </Box>
        </>
      )}
    </>
  );
};

export default ClassCapacity;
