import { ApolloError, useQuery } from '@apollo/client';
import { setIntercomUser } from '@utils';
import { errorCodes } from 'graphql/constants';
import { SupplierAdminQuery } from 'graphql/queries';
import { SupplierAdminMemberships } from 'types';
import { MarketplaceSupplierType, SupplierAdmin } from 'interfaces';
import { PebbleUseEnum } from 'enums';
import { useRouter } from 'next/router';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';

export interface SupplierAdminContextType {
  supplierDetails?: MarketplaceSupplierType;
  supplierAdmin?: SupplierAdmin;
  supplierId?: string;
  loading: boolean;
  pebbleUse?: PebbleUseEnum;
  hasRegistersAccess: boolean;
  isDebtor: boolean;
}

export const SupplierAdminContext = createContext<SupplierAdminContextType | null>(null);

interface SupplierAdminContextProviderProps {
  token?: string;
  children: ReactNode;
}

type SupplierAdminQueryResponseType = {
  supplierAdmin: SupplierAdmin;
};

export const SupplierAdminContextProvider: FC<SupplierAdminContextProviderProps> = ({
  children,
  token,
}) => {
  const router = useRouter();
  const { supplierId } = router.query;

  const { data, error, loading } = useQuery<SupplierAdminQueryResponseType>(SupplierAdminQuery, {
    skip: !token,
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!supplierId && !loading && data?.supplierAdmin?.suppliers[0]) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, supplierId: data.supplierAdmin.suppliers[0].id },
      });
    }
  }, [data?.supplierAdmin?.suppliers, supplierId, router, loading]);

  if (error) {
    if (error.message === errorCodes.SUPPLIER_ADMIN_DOES_NOT_EXIST) {
      router.push('/onboarding');
    } else {
      reportError(error as ApolloError);
      showErrorMessage(error);
    }
  }

  useEffect(() => {
    if (data?.supplierAdmin) {
      const { id, fullName } = data.supplierAdmin;

      setIntercomUser(id, fullName /*, intercomHash */);
    }
  }, [data?.supplierAdmin]);

  const supplierDetails = useMemo(() => {
    return data?.supplierAdmin?.suppliers.find(
      (supplier: MarketplaceSupplierType) => supplier.id === supplierId,
    );
  }, [data?.supplierAdmin?.suppliers, supplierId]);

  const isDebtor = useMemo((): boolean => {
    const findSupplier = data?.supplierAdmin?.suppliers.find(
      (supplier: MarketplaceSupplierType) => supplier.id === supplierId,
    );

    return findSupplier?.isDebtor ?? false;
  }, [data?.supplierAdmin?.suppliers, supplierId]);

  const hasRegistersAccess = useMemo((): boolean => {
    const findSupplier = data?.supplierAdmin?.memberships.find(
      (membership: SupplierAdminMemberships) => membership.supplier.id === supplierId,
    );

    return findSupplier?.hasRegistersAccess ?? false;
  }, [data?.supplierAdmin?.memberships, supplierId]);

  useEffect(() => {
    if (isDebtor) {
      router.push(`/restricted-access?supplierId=${supplierId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId, isDebtor]);

  useEffect(() => {
    if (hasRegistersAccess && !router.asPath.includes('grouped-session-registers')) {
      router.push(`/session-registers?supplierId=${supplierId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId, hasRegistersAccess]);

  const value: SupplierAdminContextType = {
    supplierAdmin: data?.supplierAdmin,
    supplierId: supplierDetails?.id,
    supplierDetails,
    loading,
    pebbleUse: supplierDetails?.pebbleUse,
    hasRegistersAccess,
    isDebtor,
  };

  return <SupplierAdminContext.Provider value={value}>{children}</SupplierAdminContext.Provider>;
};

export const useSupplierAdmin = (): SupplierAdminContextType => {
  const context = useContext(SupplierAdminContext);
  if (!context) {
    throw new Error('useSupplierAdmin must be used with SupplierAdminContextProvider');
  }
  return context;
};
