import { FC, useState } from 'react';
import { Text, Modal, Group, Title, Box } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import classes from '../../TermDates.module.scss';
import { Term } from 'types';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useMemo } from 'react';
import isBetween from 'dayjs/plugin/isBetween';
import { PebbleButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';

dayjs.extend(isBetween);

interface ITermViewingModal {
  showViewingModal: boolean;
  onClose(): void;
  chosenTerm: Term;
}

export const TermViewingModal: FC<ITermViewingModal> = ({
  showViewingModal,
  onClose,
  chosenTerm,
}) => {
  const [month, setMonth] = useState(new Date());

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const minMaxProps = {
    minDate: new Date(chosenTerm.startDate),
    maxDate: new Date(chosenTerm.endDate),
  };

  useEffect(() => {
    const getDate = new Date(chosenTerm.startDate);
    setMonth(getDate);
  }, [chosenTerm.startDate]);

  const reset = () => {
    setMonth(new Date(chosenTerm.startDate));
  };

  const offDays = useMemo(() => {
    return chosenTerm?.offDates?.map((offDay: string) => {
      return dayjs(offDay);
    });
  }, [chosenTerm.offDates]);

  const getDayClass = (date: Date) => {
    const dayjsDate = dayjs(date);
    const dayjsStart = dayjs(new Date(chosenTerm.startDate));
    const dayjsEnd = dayjs(new Date(chosenTerm.endDate));
    const isOffDay = offDays.some((day: dayjs.Dayjs) => {
      return dayjsDate.isSame(day, 'day');
    });

    if (isOffDay) {
      return classes.selectedDay;
    }
    if (dayjsDate.isSame(dayjsStart, 'day') || dayjsDate.isSame(dayjsEnd, 'day')) {
      return classes.boundaryDay;
    }
    if (dayjsDate.isBetween(dayjsStart, dayjsEnd)) {
      return classes.betweenDay;
    }

    return '';
  };

  return (
    <Modal
      opened={showViewingModal}
      onClose={onClose}
      withCloseButton={false}
      centered
      size={isMobile ? 'sm' : 'md'}
      classNames={{
        content: classes.modal,
        header: classes.modalHeader,
      }}
      title="Term Overview"
    >
      <Title order={4} className={classes.modalTitle}>
        {chosenTerm.name} Overview
      </Title>
      <Group className={classes.termDates}>
        <Text fw="bold">Term dates</Text>
        <Text fw="600">
          {dayjs(chosenTerm.startDate).format('ddd, D MMM YYYY')} to{' '}
          {dayjs(chosenTerm.endDate).format('ddd, D MMM YYYY')}
        </Text>
      </Group>
      <Group justify="center" mt="lg" className={classes.centerWrapper}>
        <Box className={classes.calendarWrapper}>
          <Calendar
            classNames={{
              day: classes.dayTermViewingModal,
              month: classes.disabled,
            }}
            date={month}
            onDateChange={setMonth}
            getDayProps={(date: Date) => ({
              className: getDayClass(date),
            })}
            mb="md"
            size={isMobile ? 'sm' : 'md'}
            hideOutsideDates
            withCellSpacing={false}
            {...minMaxProps}
          />
          <div className={classes.calendarKey}>
            <div className={classes.flexKey}>
              <div className={classes.termDateKey} />
              <Text size="sm" fw={600}>
                Term dates
              </Text>
            </div>
            <div className={classes.flexKey}>
              <div className={classes.offDaysKey} />
              <Text size="sm" fw={600}>
                Off days
              </Text>
            </div>
          </div>
        </Box>
        <PebbleButtonSet
          btnVariant={PebbleButtonsEnum.SECONDARY}
          size="lg"
          onClick={() => {
            onClose();
            reset();
          }}
          mb="md"
        >
          {' '}
          Close
        </PebbleButtonSet>
      </Group>
    </Modal>
  );
};
