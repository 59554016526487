import { gql } from '@apollo/client';

const createManualInvoiceMutation = gql`
  mutation createManualInvoice($input: CreateManualInvoiceInput!) {
    createManualInvoice(input: $input) {
      manualInvoice {
        id
        customerName
        invoiceNumber
        invoiceLink
      }
    }
  }
`;

export default createManualInvoiceMutation;
