import { gql } from '@apollo/client';

const sendManualInvoiceEmail = gql`
  mutation sendManualInvoiceEmail($invoiceId: UUID!) {
    sendManualInvoiceEmail(invoiceId: $invoiceId) {
      manualInvoice {
        id
        customerName
        invoiceNumber
      }
    }
  }
`;

export default sendManualInvoiceEmail;
