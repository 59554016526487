import { FC } from 'react';
import { Box, Modal, Text, Group, useMantineTheme } from '@mantine/core';
import classes from './DeleteModal.module.scss';
import { PebbleButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';

interface IDeleteModalProps {
  open: boolean;
  onDelete(): void;
  onCancel(): void;
  termName: string;
}

export const DeleteModal: FC<IDeleteModalProps> = ({ open, onDelete, onCancel, termName }) => {
  const theme = useMantineTheme();

  return (
    <Modal
      opened={open}
      onClose={onCancel}
      withCloseButton={false}
      centered
      title="Delete Term"
      classNames={{
        header: classes.header,
      }}
    >
      <Box
        style={{
          textAlign: 'center',
          padding: '24px',
        }}
      >
        <Text fw={800} size="xl" c={theme.colors.blue[5]} mt={24}>
          {`Are you sure you want to delete this term: ${termName}?`}
        </Text>
        <Group justify="center" mt={24}>
          <PebbleButtonSet btnVariant={PebbleButtonsEnum.PRIMARY} size="md" onClick={onDelete}>
            Delete
          </PebbleButtonSet>
          <PebbleButtonSet btnVariant={PebbleButtonsEnum.SECONDARY} size="md" onClick={onCancel}>
            Cancel
          </PebbleButtonSet>
        </Group>
      </Box>
    </Modal>
  );
};
