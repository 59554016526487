import { gql } from '@apollo/client';

const PauseSubscriptionBooking = gql`
  mutation pauseSubscriptionBooking($pauseDetails: String, $subscriptionId: String!) {
    pauseSubscriptionBooking(pauseDetails: $pauseDetails, subscriptionId: $subscriptionId) {
      subscriptionRequest {
        id
        status
      }
    }
  }
`;

export default PauseSubscriptionBooking;
